import React from 'react'
import { graphql } from 'gatsby';
import './ProjectList.scss';
import Pagination from '../Pagination';
import PostListItem from '../PostListItem'
import ProjectListItem from '../ProjectListItem'
import { PostListNavigation } from '../PostListNavigation'

export default class IndexPage extends React.Component {
  render() {
    let { posts, pageContext, categoryFilter, pathPrefix, siteMetadata } = this.props
    /* Custom path prefix resolver to make sure the reports slug sits next to investors */
    return (
      <section className="project-list">
        <PostListNavigation type={'report'} pathPrefix={pathPrefix} categoryFilter={categoryFilter}/>
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="list">
                {posts.map((slide, index) => {
                  return (
                    <ProjectListItem key={index} data={slide.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} />
                  )
                })}
              </div>
              <Pagination pageContext={pageContext} pathPrefix={pathPrefix} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}


export const pageQuery = graphql`
  fragment ReportListFields on wordpress__wp_reports {
    id
    categories {
      id
      name
      slug
    }
    author {
      name
      slug
      avatar_urls {
        wordpress_48
      }
    }
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    featured_image_url {
      source_url
    }
    reportData: acf {
      reportFile {
        url
        title
        filesize
      }
      reportDate
    }
  }
`
