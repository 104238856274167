import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import './Pagination.scss';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const Pagination = ({ pageContext, pathPrefix }) => {
  if (!pageContext) {
    return null;
  }
  const { slug = null, previousPagePath = null, nextPagePath = null, numberOfPages = null } = pageContext
  var pages = [];
  for (var i = 0; i < numberOfPages; i++) {
        pages.push(i+1);
  }
  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        {previousPagePath && (
          <GatsbyLink to={`/${previousPagePath}/`} className="prev"><FaChevronLeft/></GatsbyLink>
        )}
        {pages.map((key, index) => {
          return (
            slug 
            ? <GatsbyLink key={index} to={key !== 1 ? `/${pathPrefix}/${slug}/page/${key}/` : `/${pathPrefix}/${slug}/` } activeClassName="active">{key}</GatsbyLink>
            : <GatsbyLink key={index} to={key !== 1 ? `/${pathPrefix}/page/${key}/` : `/${pathPrefix}/` } activeClassName="active">{key}</GatsbyLink>
          )})}
        {nextPagePath && (
          <GatsbyLink to={`/${nextPagePath}/`} className="next"><FaChevronRight/></GatsbyLink>
        )}
      </div>
    </nav>
  )
}

export default Pagination
