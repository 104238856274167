import React from 'react'
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent';
import { Image } from '../../Image';
import { FaChevronRight } from 'react-icons/fa';
import './ProjectListItem.scss'
import { decodeEntities } from '../../../utils/helpers';

export default class ListItem extends React.Component {
  render () {
    let { data, pathPrefix } = this.props;
    const { excerpt, title, slug, featured_image_url, reportData = {} } = data
    const categorySlug = data.categories[0].slug;
    if (pathPrefix.includes('reports')) {
      pathPrefix += `/${categorySlug}`;
    }
    let reportUrl;
    let reportTitle;
    const { reportDate } = reportData;
    if (reportData.reportFile) {
      reportUrl = reportData.reportFile.url;
      reportTitle = reportData.reportFile.title;
    }

    return (
      <div className="post-list-item">
        <div className="project-list-item-inner">
          {reportUrl ? 
            <>
              <div className="image"><Image src={featured_image_url} className="background" />            
                <a className="downloadable-link" href={reportUrl} alt={reportTitle ? reportTitle : 'Downloadable File'}></a>
              </div>
              <div className="inner">
                <>
                {title && reportDate 
                  ? <h4 className="title">{decodeEntities(title)}<p>{reportDate}</p></h4>
                  : <h4 className="title">{decodeEntities(title)}</h4>
                }
                </>
                {excerpt && <RenderContent content={`${excerpt}`}/>}
              </div>
            </>
          : 
            <>
              <div className="image"><GatsbyLink to={`/${pathPrefix}/${slug}/`} label={title}><Image src={featured_image_url} className="background" /></GatsbyLink></div>
              <div className="inner">
                <GatsbyLink to={`/${pathPrefix}/${slug}/`}><h4 className="title">{decodeEntities(title)}</h4></GatsbyLink>
                {excerpt && <RenderContent content={`${excerpt}`}/>}
                {slug && <GatsbyLink to={`/${pathPrefix}/${slug}/`} label={title} className="post-link"><FaChevronRight/></GatsbyLink>}
              </div>  
            </>
          }
        </div>
      </div>
    )
  }
}
